import {Typography} from "@mui/joy";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";

export const AppliedCard = ({step, apply, cancelApply}) => {
    let disabled = false
    let cardTitle, cardSubtitle, buttonText, buttonVariant, buttonFunction;
    switch (step) {
        case 0:
            cardTitle = "Je bent nog niet aangemeld voor deze les"
            cardSubtitle = "Meld je nu aan"
            buttonText = "Aanmelden"
            buttonVariant = "success"
            buttonFunction = apply
            break
        default:
            cardTitle = "Je hebt je aangemeld voor deze les"
            cardSubtitle = ""
            buttonText = "Afmelden"
            buttonVariant = "danger"
            buttonFunction = cancelApply
    }
    if (step >= 1) {
        disabled = true
    }
    return (<Card className={"apply-card"} style={disabled ? {filter: "blur(1px)"} : {borderColor: "#0b6bcb"}}>
        <Typography level={"title-sm"}>Aanmelden</Typography>
        <CardContent>
            <Typography level={"body-md"}>{cardTitle}</Typography>
            <Typography level={"body-md"}>{cardSubtitle}</Typography>
        </CardContent>
        <CardOverflow>
            <CardActions>
                <Button disabled={disabled} onClick={buttonFunction} color={buttonVariant}>{buttonText}</Button>
            </CardActions>
        </CardOverflow>
    </Card>)
}
