import axios from "axios";
import {keys} from "../config/keys.dev";

export const handleLogin = async (username, password) => {
    const response = await axios.post(keys.url + `/api/login/`, {username, password})
    let token = response.data?.token || "error"
    let role = response.data?.role || "error"
    return {token, role}
}

export const handleLogout = async () => {
    const response = await axios.get(keys.url + '/api/logout/')
    localStorage.removeItem("userToken")
    window.location.href = "/login"
}
