import {DelphiAPIClient} from "./axios-instance";
import {keys} from "../config/keys.dev";

export const getApplicants = async (courseId, setApplicants) => {
    const response = await DelphiAPIClient.get(keys.url + `/api/get_applicants/${courseId}/`)
    setApplicants(response.data)
}

export const acceptInvitation = async (courseId) => {
    await DelphiAPIClient.get(keys.url + `/api/accept_invitation/${courseId}/`)

}
export const declineInvitation = async (courseId) => {
    await DelphiAPIClient.get(keys.url + `/api/decline_invitation/${courseId}/`)

}

export const inviteApplicant = async (courseId, applicantId) => {
    await DelphiAPIClient.get(keys.url + `/api/invite_applicant/${courseId}/${applicantId}`)
}

export const removeApplicant = async (courseId, applicantId) => {
    await DelphiAPIClient.get(keys.url + `/api/remove_applicant/${courseId}/${applicantId}`)
}

export const getLatestApplications = async (setApplications) => {
    const response = await DelphiAPIClient.get(keys.url + `/api/get_latest_applications/`)
    setApplications(response.data)
}

export const getOpenApplications = async (setOpenApplications) => {
    const response = await DelphiAPIClient.get(keys.url + "/api/get_open_applications/")
    setOpenApplications(response.data)
}

export const getApplication = async (setApplication, courseId) => {
    const response = await DelphiAPIClient.get(keys.url + "/api/get_application/" + courseId + "/")
    setApplication(response.data)
}

export const getMyApplicationStatus = async (setApplicationStatus, courseId) => {
    const response = await DelphiAPIClient.get(keys.url + "/api/get_my_application_status/" + courseId + "/")
    setApplicationStatus(response.data)
}

export const getApplicationStatus = async (setApplicationStatus, courseId, userId) => {
    const response = await DelphiAPIClient.get(keys.url + "/api/get_application_status/" + courseId + "/" + userId + "/")
    setApplicationStatus(response.data)
}
