import {useEffect, useState} from "react";
import {getSchool} from "../../actions/school-calls";
import {Divider, Typography} from "@mui/joy";
import "./school.css"
import {useParams} from "react-router-dom";
import {getCoursesBySchoolId} from "../../actions/course-calls";
import {CourseCard} from "../cards/CourseCard";
import {SchoolProfileCard} from "../cards/SchoolProfileCard";

export const School = () => {
    const [school, setSchool] = useState({})
    const [courses, setCourses] = useState([])
    const {schoolId} = useParams()
    useEffect(() => {
        getSchool(schoolId, setSchool)
        getCoursesBySchoolId(schoolId, setCourses)
    }, []);
    return <div className={"school-container"}>
        <SchoolProfileCard school={school}/>

        {/*<div className={"school-title-container"}><Typography level={"h1"}>School:</Typography> <Typography*/}
        {/*    level={"h2"}>{school.name}</Typography></div>*/}
        {/*<Typography level={"body"}>{school.description}</Typography>*/}
        <Divider orientation={"horizontal"} sx={{mt: 2, mb: 2}}/>
        <Typography level={"h1"} style={{color: "#272D2D"}}>Lessen</Typography>

        <div className={"course-container"}>
            {courses.map(course => {
                return <CourseCard course={course}/>
            })}
            {courses.length === 0 && <Typography>Deze school heeft geen openstaande lessen</Typography>}
        </div>
    </div>
}
