export function animateToCenter(userId) {
    const clickableDiv = document.querySelector('.clickable-div-' + userId);
    const flexContainer = document.querySelector('.flex-container');
    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;

    const divRect = clickableDiv.getBoundingClientRect();
    const divX = divRect.left + window.scrollX;
    const divY = divRect.top + window.scrollY;

    const translateX = centerX - (divX + divRect.width / 2);
    const translateY = centerY - (divY + divRect.height / 2);
    console.log(translateY, translateX,)
    console.log(centerY, centerX)
    clickableDiv.style.transform = 'translate(' + translateX + 'px, ' + translateY + 'px)';
    flexContainer.style.flexWrap = "nowrap"
}

export function animateBack(userId) {
    const clickableDiv = document.querySelector('.clickable-div-' + userId);
    const flexContainer = document.querySelector('.flex-container');
    clickableDiv.style.transform = 'translate(0, 0)';
    flexContainer.style.flexWrap = "wrap"
}
