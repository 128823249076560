import * as React from 'react';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/joy/CircularProgress';
import Typography from '@mui/joy/Typography';
import SvgIcon from '@mui/joy/SvgIcon';
import {
    AccessTimeRounded, EmojiEventsRounded,
    EuroSymbolRounded,
    HourglassBottomRounded,
    Person,
    PunchClock,
    SchoolRounded
} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import "./card.css"
import {FormControl, Input} from "@mui/joy";
import FormLabel from "@mui/joy/FormLabel";
import {Select} from "@mui/joy";
import Option from '@mui/joy/Option';
import {ClockIcon} from "@mui/x-date-pickers";
import {postGoal} from "../../actions/goal-calls";

export const GoalCard = ({amount, goalAmountFilled, goal, rawGoal, updateGoal, variant = "hours"}) => {
    const [open, setOpen] = useState(false)
    const [goalType, setGoalType] = useState("monetary")
    const [timeWindow, setTimeWindow] = useState("monthly")
    const [goalAmount, setGoalAmount] = useState(20)
    useEffect(() => {
        if (rawGoal && rawGoal.type) {
            setGoalType(rawGoal.type)
            setTimeWindow(rawGoal.time_window)
            setGoalAmount(rawGoal.goal)
        }
    }, [rawGoal]);

    let icon = (<SvgIcon>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
            />
        </svg>
    </SvgIcon>)
    let prefixIcon = "€"
    let title = "Behaalde vergoeding"
    let color = "primary"
    let primaryAction = "Bekijk lessen"
    let secondaryAction = "Doel aanpassen"
    let primaryLink = "/lessen"
    if (variant === "hours") {
        icon = <HourglassBottomRounded/>
        title = "Ingevallen uren"
        color = "success"
        prefixIcon = ""
        amount = Math.round(amount).toFixed(1)
        primaryAction = "Bekijk lessen"
        secondaryAction = "Doel aanpassen"
        primaryLink = "/lessen"
    } else if (variant === "applicants") {
        icon = <Person/>
        title = "Open aanmeldingen"
        color = "neutral"
        prefixIcon = ""
        primaryAction = "Bekijk aanmeldingen"
        primaryLink = "/profiel/applications"
        if (amount === 0) {
            primaryAction = "Zoek een les"
            primaryLink = "/lessen"
        }
        secondaryAction = ""
    }
    let openStyle = {minWidth: 300};
    let content = (<Card variant="solid" color={color} invertedColors sx={openStyle}>
        <CardContent orientation="horizontal">
            <CircularProgress size="lg" determinate value={goalAmountFilled}>
                {icon}
            </CircularProgress>
            <CardContent>
                <Typography level="body-md">{title}</Typography>
                <Typography
                    level="h2">{prefixIcon}{amount}{variant !== "applicants" && "/"}{goal ? parseFloat(goal).toFixed(1) : ""}</Typography>
            </CardContent>
        </CardContent>
        <CardActions>
            {secondaryAction && <Button variant="soft" size="sm" onClick={() => setOpen(true)}>
                {secondaryAction}
            </Button>}
            <Button variant="solid" component={Link} size="sm" to={primaryLink}>
                {primaryAction}
            </Button>
        </CardActions>
    </Card>)
    if (open) {
        console.log(rawGoal)
        openStyle = {
            position: "fixed",
            zIndex: 100000,
            top: "calc(50% - 160px)",
            marginLeft: "auto",
            marginRight: "auto",
            height: 320,
        }
        content = (<Card variant="solid" color={color} invertedColors sx={openStyle}>
            <CardContent orientation="vertical">
                <Typography level="h2">Doel aanpassen</Typography>
                <FormControl>
                    <FormLabel>Goal type</FormLabel>
                    <Select
                        size="sm"
                        value={goalType}
                        onChange={(e, val) => setGoalType(val)}
                        startDecorator={<EmojiEventsRounded/>}
                        slotProps={{
                            listbox: {
                                sx: {
                                    zIndex: 999999999
                                }
                            }
                        }}
                    >
                        <Option value={"hours"}>Aantal uren</Option>
                        <Option value={"finance"}>Extra beloning</Option>
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>Tijdsbestek</FormLabel>
                    <Select
                        slotProps={{
                            listbox: {
                                sx: {
                                    zIndex: 999999999
                                }
                            }
                        }}
                        size="sm"
                        value={timeWindow}
                        onChange={(e, val) => setTimeWindow(val)}
                        startDecorator={<ClockIcon/>}
                    >
                        <Option value={"weekly"}>Wekelijks</Option>
                        <Option value={"monthly"}>Maandelijks</Option>
                        <Option value={"quarterly"}>Kwartaal</Option>
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>Hoeveelheid</FormLabel>
                    <Input value={goalAmount} type={"number"}
                           onChange={(e) => setGoalAmount(parseFloat(e.target.value))}/>
                </FormControl>

            </CardContent>
            <CardActions>
                <Button variant="soft" size="sm" onClick={() => setOpen(false)}>
                    Annuleren
                </Button>
                <Button variant="solid" size="sm"
                        onClick={() => postGoal(rawGoal.id, goalType, goalAmount, timeWindow, () => {
                            setOpen(false)
                            updateGoal()
                        })}>
                    Opslaan
                </Button>
            </CardActions>
        </Card>)
    }
    return (
        <>
            {open && <div className={"goal-overlay"} onClick={() => setOpen(false)}></div>}
            {content}
        </>
    );
}
