import {AspectRatio, Button, Card, CardActions, CardContent, Typography} from "@mui/joy";
import {useEffect, useState} from "react";

export const SchoolCard = ({school}) => {

    const [truncateLength, setTruncateLength] = useState(window.innerWidth)
    useEffect(() => {
        const handleResize = () => {
            let newTruncateLength = 120;
            if (window.innerWidth > 900) {
                newTruncateLength = 220
            }
            if (window.innerWidth > 1200) {
                newTruncateLength = 340
            }
            if (window.innerWidth > 1400) {
                newTruncateLength = 440
            }
            setTruncateLength(newTruncateLength);
        };

        // Attach event listener for window resize
        window.addEventListener('resize', handleResize);

        // Initial call to set truncateLength based on the current width
        handleResize();

        // Clean up the event listener when the component unmounts
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (<Card key={school.name} sx={{width: {xs: '90%', md: '70%'}, padding: 0}}
                  orientation={truncateLength > 120 ? "horizontal" : "vertical"}>
        <AspectRatio ratio="1" maxHeight={truncateLength === 120 ? 150 : "100%"}
                     sx={{
                         width: truncateLength > 120 ? 250 : "100%",
                         minHeight: truncateLength > 120 ? "100%" : "",
                         borderRadius: {xs: "8px 8px 0 0", md: "8px 0px 0px 8px"}
                     }}
                     objectFit={"cover"}>
            <img
                src={school.school_picture}
                loading="lazy"
                alt=""
            />
        </AspectRatio>
        <CardContent style={{padding: 10}}>
            <div>
                <Typography level="body-xs">{school.city}</Typography>
                <Typography fontSize="lg" fontWeight="lg">
                    {school.name}
                </Typography>
                <Typography
                    level={"body"}>{school.description && school.description.length > truncateLength ? school.description.substring(0, truncateLength) + "..." : school.description}</Typography>
            </div>
            <Button
                variant="solid"
                size="md"
                color="primary"
                aria-label={"Bekijk " + school.name}
                sx={{ml: 'auto', alignSelf: 'center', fontWeight: 600, marginTop: "auto"}}
                onClick={() => window.location.href = "/school/" + school.id}>
                Bekijken
            </Button>
        </CardContent>

    </Card>)
}
