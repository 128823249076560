import Button from "@mui/joy/Button";
import {DelphiAPIClient} from "../../actions/axios-instance";
import {useState} from "react";
import {keys} from "../../config/keys.dev";
export const Upload = () => {

    const [uploadedFile, setUploadedFile] = useState(null)
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        setUploadedFile(file)
    };


    return <>
        <h4>Uploaded File:</h4>
        <input type="file" onChange={handleFileChange}/>
        <Button variant={"outlined"} color={"primary"} onClick={async () => {
            const formData = new FormData();
            formData.append('file', uploadedFile);
            try {
                const response = await DelphiAPIClient.post(keys.url + '/api/upload/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }}>Upload</Button>
    </>
}