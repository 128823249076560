import {DelphiAPIClient} from "./axios-instance";
import {keys} from "../config/keys.dev";

export const getCourses = async (setCourses) => {
    const response = await DelphiAPIClient.get(keys.url + "/api/get_courses/")
    setCourses(response.data)
}

export const getRecommendedCourses = async (setCourses) => {
    const response = await DelphiAPIClient.get(keys.url + "/api/get_recommended_courses/")
    setCourses(response.data)
}

export const findCourses = async (courseType, qualification, days, dayParts, maxDistance, accountPreferences, setCourses) => {
    const response = await DelphiAPIClient.post(keys.url + "/api/find_courses/", {
        courseType,
        qualification,
        days,
        dayParts,
        maxDistance,
        accountPreferences
    })
    setCourses(response.data)
}

export const saveCourse = async (course, setCourse) => {
    const response = await DelphiAPIClient.post(keys.url + "/api/save_course/", course)
    setCourse(response.data)
}

export const retrieveCourseCreation = async (courseId, setCourse) => {
    const response = await DelphiAPIClient.get(keys.url + `/api/retrieve_course_creation/${courseId}/`)
    setCourse(response.data)
}


export const getAppliedCourses = async (setCourses) => {
    const response = await DelphiAPIClient.get(keys.url + "/api/get_applied_courses/")
    setCourses(response.data)
}


export const getCompletedCourses = async (setCourses) => {
    const response = await DelphiAPIClient.get(keys.url + "/api/get_completed_courses/")
    setCourses(response.data)
}


export const getCoursesBySchoolId = async (schoolId, setCourses) => {
    const response = await DelphiAPIClient.get(keys.url + `/api/get_courses_by_school_id/${schoolId}/`)
    setCourses(response.data)
}
export const getCourse = async (courseId, setCourse) => {
    const response = await DelphiAPIClient.get(keys.url + `/api/get_course/${courseId}/`)
    setCourse(response.data)
}

export const getApplied = async (courseId, setApplied) => {
    const response = await DelphiAPIClient.get(keys.url + `/api/get_applied/${courseId}/`)
    setApplied(response.data)
}

export const apply = async (courseId, setApplied) => {
    const response = await DelphiAPIClient.get(keys.url + `/api/apply/${courseId}/`)
    setApplied(response.data.applied)
}

export const deleteCourse = async (courseId, callback) => {
    const response = await DelphiAPIClient.get(keys.url + `/api/delete_course/${courseId}/`)
    callback()
}


