import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import FormHelperText from "@mui/joy/FormHelperText";
import {BadgeRounded, Check, Close, InfoOutlined, LocationCityRounded} from "@mui/icons-material";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import Button from "@mui/joy/Button";
import React, {useEffect, useState} from "react";
import {inviteUser} from "../../actions/profile-calls";
import {Select} from "@mui/joy";
import Option from "@mui/joy/Option";
import {getSchools} from "../../actions/school-calls";

export const InviteUserCard = () => {

    const [errors, setErrors] = useState({})
    const [titleLoading, setTitleLoading] = useState(false)
    const [titleCancelled, setTitleCancelled] = useState(false)
    const [titleSaved, setTitleSaved] = useState(false)
    const [titleCancelling, setTitleCancelling] = useState(false)
    const [school, setSchool] = useState("")
    const [schools, setSchools] = useState([])
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [role, setRole] = useState("")

    useEffect(() => {
        getSchools(setSchools)
    }, []);

    const resetFields = (fields) => {
        setFirstname("")
        setLastname("")
        setEmail("")
        setRole("")
        setSchool("")
        setTitleCancelling(true)
        setErrors({})
        setTimeout(() => {
            setTitleCancelling(false)
            setTitleCancelled(true)
            setTimeout(() => setTitleCancelled(false), 1000)
        }, 1000)
    }

    const saveFields = (fields) => {
        if (!school) {
            setErrors({school: "Geen school ingevuld"})
            return
        }
        if (!role) {
            setErrors({role: "Geen rol geselecteerd"})
            return
        }
        if (!email) {
            setErrors({email: "Geen email ingevuld"})
            return
        }
        if (!firstname) {
            setErrors({firstname: "Geen voornaam ingevuld"})
            return
        }
        if (!lastname) {
            setErrors({lastname: "Geen achternaam ingevuld"})
            return
        }
        if (titleLoading)
            return
        setTitleLoading(true)

        setTimeout(() => {
            setTitleLoading(false)
            setTitleSaved(true)
            setFirstname("")
            setLastname("")
            setEmail("")
            setSchool("")
            setRole("")
            setTimeout(() => setTitleSaved(false), 1000)
        }, 1000)
        inviteUser(firstname, lastname, email, school, role)

    }

    return (<Box sx={{
        width: 'calc(100vw - 356px)',
        left: 256, padding: "40px", position: "relative",
        minWidth: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: 8
    }}>
        <Stack
            spacing={4}
            sx={{
                display: 'flex',
                maxWidth: '100%',
                width: {xs: '90%', md: '70%'},
                px: {xs: 2, md: 6},
                py: {xs: 2, md: 3},
            }}
        ><Card>
            <Box sx={{mb: 1}}>
                <Typography level="title-md">Gebruiker</Typography>
                <Typography level="body-sm">
                    Vul alle gegevens van de gebruiker in.
                </Typography>
            </Box>
            <Divider/>
            <Stack spacing={2} sx={{my: 1}}>
                <FormControl error={!!errors.firstname}>
                    <FormLabel>Voornaam</FormLabel>
                    <Input size="sm" placeholder={"Jan"} value={firstname}
                           onChange={(e) => setFirstname(e.target.value)}/>
                    <FormHelperText style={{display: errors.firstname ? "flex" : "none"}}>
                        <InfoOutlined/>
                        {errors.firstname}
                    </FormHelperText>
                </FormControl>
                <FormControl error={!!errors.lastname}>
                    <FormLabel>Achternaam</FormLabel>

                    <Input size="sm" placeholder={"Schippers"} value={lastname}
                           onChange={(e) => setLastname(e.target.value)}/>
                    <FormHelperText style={{display: errors.lastname ? "flex" : "none"}}>
                        <InfoOutlined/>
                        {errors.lastname}
                    </FormHelperText>
                </FormControl>
                <FormControl error={!!errors.email}>
                    <FormLabel>Email</FormLabel>
                    <Input size="sm" placeholder={"janschippers@gmail.com"} value={email}
                           onChange={(e) => setEmail(e.target.value)}/>
                    <FormHelperText style={{display: errors.email ? "flex" : "none"}}>
                        <InfoOutlined/>
                        {errors.email}
                    </FormHelperText>
                </FormControl>
                <FormControl error={!!errors.school}>
                    <FormLabel>School</FormLabel>
                    {schools.length > 0 && <Select
                        size="sm"
                        startDecorator={<LocationCityRounded/>}
                        value={school}
                        onChange={(e, val) => setSchool(val)}
                    >
                        {schools.map(s => {
                            return (<Option value={s.id}>{s.name}</Option>)
                        })}
                    </Select>}
                    <FormHelperText style={{display: errors.school ? "flex" : "none"}}>
                        <InfoOutlined/>
                        {errors.school}
                    </FormHelperText>
                </FormControl>
                <FormControl error={!!errors.role}>
                    <FormLabel>Rol</FormLabel>
                    <Select
                        size="sm"
                        startDecorator={<BadgeRounded/>}
                        value={role}
                        onChange={(e, val) => setRole(val)}
                    >
                        <Option value={"Administrator"}>Administrator</Option>
                        <Option value={"Lesgever"}>Lesgever</Option>
                    </Select>
                    <FormHelperText style={{display: errors.role ? "flex" : "none"}}>
                        <InfoOutlined/>
                        {errors.role}
                    </FormHelperText>
                </FormControl>
            </Stack>
            <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                <CardActions sx={{alignSelf: 'flex-end', pt: 2}}>
                    <Button disabled={titleLoading} size="sm" variant="outlined"
                            onClick={() => resetFields("title")} color={titleCancelled ? "danger" : "neutral"}
                            loading={titleCancelling}
                            startDecorator={titleCancelled && <Close/>} className={"color-button"}
                    >
                        {titleCancelled ? "Leeggemaakt" : "Leegmaken"}
                    </Button>
                    <Button loading={titleLoading} size="sm" variant="solid"
                            onClick={() => saveFields("title")} color={titleSaved ? "success" : "primary"}
                            startDecorator={titleSaved && <Check/>} className={"color-button"}
                    >
                        {titleSaved ? "Verstuurd" : "Versturen"}
                    </Button>
                </CardActions>
            </CardOverflow>
        </Card>
        </Stack>
    </Box>)
}