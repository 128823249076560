import {getProfile, saveProfile, updateProfilePicture} from "../../actions/profile-calls";
import Stack from "@mui/joy/Stack";
import Card from "@mui/joy/Card";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import AspectRatio from "@mui/joy/AspectRatio";
import IconButton from "@mui/joy/IconButton";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import Input from "@mui/joy/Input";
import {Check, Close, Phone} from "@mui/icons-material";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import Button from "@mui/joy/Button";
import Textarea from "@mui/joy/Textarea";
import React, {useContext, useState} from "react";
import "./card.css"
import {Select} from "@mui/joy";
import Option from '@mui/joy/Option';
import AWS from 'aws-sdk';
import {ErrorContext, MessageContext} from "../../contexts/Contexts";


export const ProfileCard = ({
                                firstname,
                                lastname,
                                title,
                                email,
                                titleExplanation,
                                phone,
                                pitch,
                                setFirstname,
                                setLastname,
                                setTitle,
                                setEmail,
                                setTitleExplanation,
                                setPhone,
                                setPitch,
                                qualifications, setQualifications,
                                dayPreferences, setDayPreferences,
                                timePreferences, setTimePreferences,
                                coursePreferences, setCoursePreferences,
                                profile,
                                setProfile,

                            }) => {

    const [nameLoading, setNameLoading] = useState(false)
    const [titleLoading, setTitleLoading] = useState(false)
    const [pitchLoading, setPitchLoading] = useState(false)
    const [qualificationsLoading, setQualificationsLoading] = useState(false)
    const [nameCancelling, setNameCancelling] = useState(false)
    const [titleCancelling, setTitleCancelling] = useState(false)
    const [pitchCancelling, setPitchCancelling] = useState(false)
    const [qualificationsCancelling, setQualificationsCancelling] = useState(false)
    const [nameCancelled, setNameCancelled] = useState(false)
    const [titleCancelled, setTitleCancelled] = useState(false)
    const [pitchCancelled, setPitchCancelled] = useState(false)
    const [qualificationsCancelled, setQualificationsCancelled] = useState(false)
    const [nameSaved, setNameSaved] = useState(false)
    const [titleSaved, setTitleSaved] = useState(false)
    const [pitchSaved, setPitchSaved] = useState(false)
    const [qualificationsSaved, setQualificationsSaved] = useState(false)
    const {setError} = useContext(ErrorContext)
    const {setMessage} = useContext(MessageContext)

// Configure AWS SDK
    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION
    });
    const s3 = new AWS.S3();
    const saveFields = (type) => {
        let loadFunction;
        let savedFunction;
        switch (type) {
            case "name":
                loadFunction = setNameLoading
                savedFunction = setNameSaved
                setNameLoading(true)
                setProfile((oldProfile) => {
                    const newProfile = {
                        ...oldProfile,
                        firstname: firstname,
                        lastname: lastname,
                        email: email,
                        phone: phone
                    }
                    saveProfile(newProfile)
                    return newProfile

                })
                break
            case "title":
                loadFunction = setTitleLoading
                savedFunction = setTitleSaved
                setTitleLoading(true)
                setProfile((oldProfile) => {
                    const newProfile = {...oldProfile, title: title, title_explanation: titleExplanation}
                    saveProfile(newProfile)
                    return newProfile
                })
                break
            case "pitch":
                loadFunction = setPitchLoading
                savedFunction = setPitchSaved
                setPitchLoading(true)
                setProfile((oldProfile) => {
                    const newProfile = {...oldProfile, pitch: pitch}
                    saveProfile(newProfile)
                    return newProfile
                })
                break
            case "qualifications":
                loadFunction = setQualificationsLoading
                savedFunction = setQualificationsSaved
                setQualificationsLoading(true)
                setProfile((oldProfile) => {
                    const newProfile = {
                        ...oldProfile,
                        qualifications: qualifications,
                        day_preferences: dayPreferences,
                        time_preferences: timePreferences,
                        course_preferences: coursePreferences,
                    }
                    saveProfile(newProfile)
                    return newProfile
                })
        }
        setTimeout(() => {
            loadFunction(false)
            savedFunction(true)
            setTimeout(() => savedFunction(false), 1000)
        }, 1000)
    }

    const uploadFile = (file, bucketName, key) => {
        const params = {
            Bucket: bucketName,
            Key: key,
            Body: file,
            ContentType: file.type
        };
        return s3.upload(params).promise();
    };

    function uuidv4() {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
        );
    }

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
        if (!allowedTypes.includes(file.type)) {
            setError("Zorg dat je een png, jpg of gif upload")
            return;
        }
        if (file) {
            try {
                const data = await uploadFile(file, process.env.REACT_APP_S3_BUCKET_NAME, `profile-pictures/${uuidv4() + file.name.substring(file.name.lastIndexOf('.') + 1)}`);
                updateProfilePicture(data.Location).then(() => {
                    getProfile(setProfile)
                    setMessage({text: "Profiel foto geupdate!", color: "neutral"})

                })
            } catch (error) {
                setError('Error uploading file: ' + error.toString());
            }
        }
    };

    const resetFields = (type) => {
        let loadFunction;
        let savedFunction;
        switch (type) {
            case "name":
                setNameCancelling(true)
                loadFunction = setNameCancelling
                savedFunction = setNameCancelled
                setFirstname(profile.firstname)
                setLastname(profile.lastname)
                setEmail(profile.email)
                setPhone(profile.phone)
                break
            case "title":
                setTitleCancelling(true)
                loadFunction = setTitleCancelling
                savedFunction = setTitleCancelled
                setTitle(profile.title)
                setTitleExplanation(profile.title_explanation)
                break
            case "pitch":
                setPitchCancelling(true)
                loadFunction = setPitchCancelling
                savedFunction = setPitchCancelled
                setPitch(profile.pitch)
                break
            case "qualifications":
                setQualificationsCancelling(true)
                loadFunction = setQualificationsCancelling
                savedFunction = setQualificationsCancelled
                setQualifications(profile.qualifications)
                setDayPreferences(profile.day_preferences)
                setTimePreferences(profile.time_preferences)
                setCoursePreferences(profile.course_preferences)
                break
        }
        setTimeout(() => {
            loadFunction(false)
            savedFunction(true)
            setTimeout(() => savedFunction(false), 1000)
        }, 1000)

    }

    return (<Box sx={{
            width: '100%',
            minWidth: 300,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: 8
        }}>
            <Stack
                spacing={4}
                sx={{
                    display: 'flex',
                    maxWidth: '100%',
                    width: {xs: '90%', md: '70%'},
                    px: {xs: 2, md: 6},
                    py: {xs: 2, md: 3},
                }}
            >
                <Card>
                    <Box sx={{mb: 1}}>
                        <Typography level="title-md">Persoonlijke informatie</Typography>
                        <Typography level="body-sm">
                            Bepaal hoe jouw persoonlijke informatie wordt laten zien.
                        </Typography>
                    </Box>
                    <Divider/>
                    <Stack
                        direction="row"
                        spacing={3}
                        sx={{display: {xs: 'none', md: 'flex'}, my: 1}}
                    >
                        <Stack direction="column" spacing={1}>
                            <AspectRatio
                                ratio="1"
                                maxHeight={200}
                                sx={{flex: 1, minWidth: 120, borderRadius: '100%'}}
                            >
                                <img
                                    src={profile.profile_picture}
                                    loading="lazy"
                                    alt=""
                                />
                            </AspectRatio>
                            <input
                                type="file"
                                id="fileInput"
                                style={{display: 'none'}}
                                onChange={handleFileChange}
                            />
                            <IconButton
                                aria-label="upload new picture"
                                size="sm"
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    bgcolor: 'background.body',
                                    position: 'absolute',
                                    zIndex: 2,
                                    borderRadius: '50%',
                                    left: 100,
                                    top: 170,
                                    boxShadow: 'sm',
                                }}
                                onClick={() => document.getElementById('fileInput').click()}

                            >
                                <EditRoundedIcon/>
                            </IconButton>
                        </Stack>
                        <Stack spacing={2} sx={{flexGrow: 1}}>
                            <Stack spacing={1}>
                                <FormLabel>Naam</FormLabel>
                                <FormControl
                                    sx={{display: {sm: 'flex-column', md: 'flex-row'}, gap: 2}}
                                >
                                    <Input size="sm" placeholder="First name" value={firstname}
                                           onChange={(e) => setFirstname(e.target.value)}/>
                                </FormControl>
                                <FormControl>
                                    <Input size="sm" placeholder="Last name" value={lastname}
                                           onChange={(e) => setLastname(e.target.value)}
                                           sx={{flexGrow: 1}}/>
                                </FormControl>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <FormControl sx={{flexGrow: 1}}>
                                    <FormLabel>Telefoonnummer</FormLabel>
                                    <Input
                                        size="sm"
                                        type="phone"
                                        startDecorator={<Phone/>}
                                        placeholder="telefoonnummer"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        sx={{flexGrow: 1}}
                                    />
                                </FormControl>
                                <FormControl sx={{flexGrow: 1}}>
                                    <FormLabel>Email</FormLabel>
                                    <Input
                                        size="sm"
                                        type="email"
                                        startDecorator={<EmailRoundedIcon/>}
                                        placeholder="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        sx={{flexGrow: 1}}
                                    />
                                </FormControl>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{display: {xs: 'flex', md: 'none'}, my: 1}}
                    >
                        <Stack direction="row" spacing={2} justifyContent={"center"}>
                            <Stack direction="column" spacing={1}>
                                <AspectRatio
                                    ratio="1"
                                    maxHeight={108}
                                    sx={{flex: 1, minWidth: 108, borderRadius: '100%'}}
                                >
                                    <img
                                        src={profile.profile_picture}
                                        loading="lazy"
                                        alt=""
                                    />
                                </AspectRatio>
                                <input
                                    type="file"
                                    id="fileInput"
                                    style={{display: 'none'}}
                                    onChange={handleFileChange}
                                />
                                <IconButton
                                    aria-label="upload new picture"
                                    size="sm"
                                    variant="outlined"
                                    color="neutral"
                                    sx={{
                                        bgcolor: 'background.body',
                                        position: 'absolute',
                                        zIndex: 2,
                                        borderRadius: '50%',
                                        left: "53%",
                                        top: 180,
                                        boxShadow: 'sm',
                                    }}
                                    onClick={() => document.getElementById('fileInput').click()}
                                >
                                    <EditRoundedIcon/>
                                </IconButton>
                            </Stack>
                        </Stack>
                        <FormLabel>Naam</FormLabel>
                        <FormControl
                            sx={{
                                display: {
                                    sm: 'flex-column',
                                    md: 'flex-row',
                                },
                                gap: 2,
                            }}
                        >
                            <Input size="sm" value={firstname} onChange={(e) => setFirstname(e.target.value)}/>
                            <Input size="sm" value={lastname} onChange={(e) => setLastname(e.target.value)}/>
                        </FormControl>
                        <FormControl sx={{flexGrow: 1}}>
                            <FormLabel>Telefoonnummer</FormLabel>
                            <Input
                                size="sm"
                                type="phone"
                                startDecorator={<Phone/>}
                                placeholder="telefoonnummer"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                sx={{flexGrow: 1}}
                            />
                        </FormControl>
                        <FormControl sx={{flexGrow: 1}}>
                            <FormLabel>Email</FormLabel>
                            <Input
                                size="sm"
                                type="email"
                                startDecorator={<EmailRoundedIcon/>}
                                placeholder="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                sx={{flexGrow: 1}}
                            />
                        </FormControl>
                    </Stack>
                    <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                        <CardActions sx={{alignSelf: 'flex-end', pt: 2}}>
                            <Button disabled={nameLoading} size="sm" variant="outlined"
                                    onClick={() => resetFields("name")}
                                    color={nameCancelled ? "danger" : "neutral"}
                                    loading={nameCancelling}
                                    startDecorator={nameCancelled && <Close/>} className={"color-button"}
                            >
                                {nameCancelled ? "Geannuleerd" : "Annuleren"}
                            </Button>
                            <Button
                                loading={nameLoading} size="sm"
                                variant="solid"
                                onClick={() => saveFields("name")}
                                color={nameSaved ? "success" : "primary"}
                                startDecorator={nameSaved && <Check/>} className={"color-button"}
                            >
                                {nameSaved ? "Opgeslagen" : "Opslaan"}
                            </Button>
                        </CardActions>
                    </CardOverflow>
                </Card>
                <Card>
                    <Box sx={{mb: 1}}>
                        <Typography level="title-md">Mijn werk</Typography>
                        <Typography level="body-sm">
                            Schrijf een korte omschrijving over je werk en taken.
                        </Typography>
                    </Box>
                    <Divider/>
                    <Stack spacing={2} sx={{my: 1}}>
                        <FormControl>
                            <FormLabel>Functie</FormLabel>
                            <Input size="sm" value={title} onChange={(e) => setTitle(e.target.value)}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Functie omschrijving</FormLabel>
                            <Textarea
                                size="sm"
                                minRows={6}
                                maxRows={6}
                                rows={6}
                                value={titleExplanation}
                                onChange={(e) => setTitleExplanation(e.target.value)}
                            />
                        </FormControl>

                    </Stack>
                    <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                        <CardActions sx={{alignSelf: 'flex-end', pt: 2}}>
                            <Button disabled={titleLoading} size="sm" variant="outlined"
                                    onClick={() => resetFields("title")} color={titleCancelled ? "danger" : "neutral"}
                                    loading={titleCancelling}
                                    startDecorator={titleCancelled && <Close/>} className={"color-button"}
                            >
                                {titleCancelled ? "Geannuleerd" : "Annuleren"}
                            </Button>
                            <Button loading={titleLoading} size="sm" variant="solid"
                                    onClick={() => saveFields("title")} color={titleSaved ? "success" : "primary"}
                                    startDecorator={titleSaved && <Check/>} className={"color-button"}
                            >
                                {titleSaved ? "Opgeslagen" : "Opslaan"}
                            </Button>
                        </CardActions>
                    </CardOverflow>
                </Card>
                <Card>
                    <Box sx={{mb: 1}}>
                        <Typography level="title-md">Pitch</Typography>
                        <Typography level="body-sm">
                            Schrijf een kort omschrijving over jezelf.
                        </Typography>
                    </Box>
                    <Divider/>
                    <Stack spacing={2} sx={{my: 1}}>
                        <Textarea
                            size="sm"
                            value={pitch}
                            minRows={6}
                            maxRows={6}
                            rows={6}

                            onChange={(e) => setPitch(e.target.value)}
                        />
                    </Stack>
                    <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                        <CardActions sx={{alignSelf: 'flex-end', pt: 2}}>
                            <Button disabled={pitchLoading} size="sm" variant="outlined"
                                    onClick={() => resetFields("pitch")} color={pitchCancelled ? "danger" : "neutral"}
                                    loading={pitchCancelling}
                                    startDecorator={pitchCancelled && <Close/>} className={"color-button"}
                            >
                                {pitchCancelled ? "Geannuleerd" : "Annuleren"}
                            </Button>
                            <Button loading={pitchLoading} size="sm" variant="solid"
                                    onClick={() => saveFields("pitch")} color={pitchSaved ? "success" : "primary"}
                                    startDecorator={pitchSaved && <Check/>} className={"color-button"}
                            >
                                {pitchSaved ? "Opgeslagen" : "Opslaan"}
                            </Button>
                        </CardActions>
                    </CardOverflow>
                </Card>
                <Card>
                    <Box sx={{mb: 1}}>
                        <Typography level="title-md">Bevoegdheden & voorkeuren</Typography>
                        <Typography level="body-sm">
                            Wat zijn je bevoegdheden en voorkeuren?
                        </Typography>
                    </Box>
                    <Divider/>
                    <Stack spacing={2} sx={{my: 1}}>
                        <FormControl>
                            <FormLabel id="select-qualifications-label" htmlFor="select-qualifications">
                                Bevoegdheden
                            </FormLabel>
                            <Select
                                size="sm"
                                value={qualifications}
                                slotProps={{
                                    button: {
                                        id: 'select-qualifications',
                                        'aria-labelledby': 'select-qualifications-label select-qualifications',
                                    },
                                }}
                                multiple={true}
                                onChange={(e, newValue) => setQualifications(newValue)}
                            >
                                <Option value={"eerste-graads"}>Eerste graads</Option>
                                <Option value={"tweede-graads"}>Tweede graads</Option>
                                <Option value={"basisonderwijs"}>Basisschool</Option>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel id="select-day-preferences-label" htmlFor="select-day-preferences">
                                Voorkeur dagen
                            </FormLabel>
                            <Select
                                size="sm"
                                value={dayPreferences}
                                multiple={true}
                                onChange={(e, newValue) => setDayPreferences(newValue)}
                                slotProps={{
                                    button: {
                                        id: 'select-day-preferences',
                                        'aria-labelledby': 'select-day-preferences-label select-day-preferences',
                                    },
                                }}
                            >
                                <Option value={"maandag"}>Maandag</Option>
                                <Option value={"dinsdag"}>Dinsdag</Option>
                                <Option value={"woensdag"}>Woensdag</Option>
                                <Option value={"donderdag"}>Donderdag</Option>
                                <Option value={"vrijdag"}>Vrijdag</Option>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel id="select-time-preferences-label" htmlFor="select-time-preferences">
                                Voorkeur tijden
                            </FormLabel>
                            <Select
                                size="sm"
                                value={timePreferences}
                                multiple={true}
                                onChange={(e, newValue) => setTimePreferences(newValue)}
                                slotProps={{
                                    button: {
                                        id: 'select-time-preferences',
                                        'aria-labelledby': 'select-time-preferences-label select-time-preferences',
                                    },
                                }}
                            >
                                <Option value={"ochtend"}>Ochtend</Option>
                                <Option value={"middag"}>Middag</Option>
                                <Option value={"avond"}>Avond</Option>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel id="select-course-preferences-label" htmlFor="select-course-preferences">
                                Vakken
                            </FormLabel>
                            <Select
                                size="sm"
                                value={coursePreferences}
                                multiple={true}
                                onChange={(e, newValue) => setCoursePreferences(newValue)}
                                slotProps={{
                                    button: {
                                        id: 'select-course-preferences',
                                        'aria-labelledby': 'select-course-preferences-label select-course-preferences',
                                    },
                                }}
                            >
                                <Option value={"nederlands"}>Nederlands</Option>
                                <Option value={"engels"}>Engels</Option>
                                <Option value={"duits"}>Duits</Option>
                                <Option value={"wiskunde"}>Wiskunde</Option>
                                <Option value={"natuurkunde"}>Natuurkunde</Option>
                            </Select>
                        </FormControl>


                    </Stack>
                    <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                        <CardActions sx={{alignSelf: 'flex-end', pt: 2}}>
                            <Button disabled={qualificationsCancelling} size="sm" variant="outlined"
                                    onClick={() => resetFields("qualifications")}
                                    color={qualificationsCancelled ? "danger" : "neutral"}
                                    loading={qualificationsCancelling}
                                    startDecorator={qualificationsCancelled && <Close/>} className={"color-button"}
                            >
                                {qualificationsCancelled ? "Geannuleerd" : "Annuleren"}
                            </Button>
                            <Button loading={qualificationsLoading} size="sm" variant="solid"
                                    onClick={() => saveFields("qualifications")}
                                    color={qualificationsSaved ? "success" : "primary"}
                                    startDecorator={pitchSaved && <Check/>} className={"color-button"}
                            >
                                {qualificationsSaved ? "Opgeslagen" : "Opslaan"}
                            </Button>
                        </CardActions>
                    </CardOverflow>
                </Card>
            </Stack>
        </Box>

    )
}
