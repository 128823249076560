import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import School from '@mui/icons-material/School';

export const CongratCard = ({title, content, setOpen}) => {
    return (
        <Card
            data-resizable
            sx={{
                textAlign: 'center',
                alignItems: 'center',
                maxWidth: 300,
                // to make the demo resizable
                overflow: 'auto',
                resize: 'horizontal',
                '--icon-size': '100px',
            }}
        >
            <CardOverflow variant="solid" color="warning">
                <AspectRatio
                    variant="outlined"
                    color="warning"
                    ratio="1"
                    sx={{
                        m: 'auto',
                        transform: 'translateY(50%)',
                        borderRadius: '50%',
                        width: 'var(--icon-size)',
                        boxShadow: 'sm',
                        bgcolor: 'background.surface',
                        position: 'relative',
                    }}
                >
                    <div>
                        <School color="warning" sx={{fontSize: '4rem'}}/>
                    </div>
                </AspectRatio>
            </CardOverflow>
            <Typography level="title-lg" sx={{mt: 'calc(var(--icon-size) / 2)'}}>
                {title}
            </Typography>
            <CardContent sx={{maxWidth: '40ch'}}>
                {content}
            </CardContent>
            <CardActions
                orientation="vertical"
                buttonFlex={1}
                sx={{
                    '--Button-radius': '40px',
                    width: 'clamp(min(100%, 160px), 50%, min(100%, 200px))',
                }}
            >
                <Button variant="solid" color="primary" onClick={() => setOpen(false)}>
                    Doorgaan
                </Button>
            </CardActions>
        </Card>
    );
}
