import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import {Chip} from "@mui/joy";

export const NewsCard = ({title, subtitle, link, image, agency, agencyIcon}) => {
    return (
        <Card variant="outlined"
              sx={{
                  width: 320,
                  minWidth: 320,
                  height: 300,
                  '&:hover': {boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder'},
                  cursor: "pointer"
              }} onClick={() => window.open(link)}>
            <CardOverflow>
                <AspectRatio ratio="2">
                    <img
                        src={image}
                        loading="lazy"
                        alt=""
                    />
                </AspectRatio>
                <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    position: "absolute",
                    justifyContent: "flex-start",
                    left: 0,
                    paddingLeft: 5,
                    paddingTop: 5
                }}>
                    <Chip variant={"outlined"} startDecorator={<img style={{width: 20}}
                                                                    src={agencyIcon}/>}
                    >{agency}</Chip>
                </div>
            </CardOverflow>
            <CardContent>
                <Typography level="title-md">{title}</Typography>
                <Typography level="body-sm">{subtitle}</Typography>
            </CardContent>
        </Card>
    );
}