import {useEffect, useState} from "react";
import {getSchools} from "../../actions/school-calls";
import {AspectRatio, Button, Card, CardActions, CardContent, CardCover, Sheet, Typography} from "@mui/joy";
import "./school.css"
import {SchoolCard} from "../cards/SchoolCard";

export const Schools = () => {
    const [schools, setSchools] = useState([])
    useEffect(() => {
        getSchools(setSchools)
    }, []);
    return <div className={"schools-container"}>
        <Typography sx={{color:"#272D2D"}} level={"h1"}>Scholen</Typography>
        {schools.map(school => {
            return <SchoolCard school={school}/>
        })}
    </div>
}
