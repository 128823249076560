import {useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {getCourse} from "../../../actions/course-calls";
import {getApplicants, inviteApplicant, removeApplicant} from "../../../actions/applicant-calls";
import {ApplicantCard} from "../../cards/ApplicantCard";
import {CourseCard} from "../../cards/CourseCard";
import {Typography} from "@mui/joy";
import {MessageContext} from "../../../contexts/Contexts";

export const ApplicantsOverview = () => {
    const {courseId} = useParams("courseId")
    const [course, setCourse] = useState({})
    const [applicants, setApplicants] = useState([])
    const {setMessage} = useContext(MessageContext)
    useEffect(() => {
        getCourse(courseId, setCourse)
        getApplicants(courseId, setApplicants)

    }, []);
    return <div
        style={{
            width: "calc(100vw - 356px)",
            left: 256,
            padding: 50,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            gap: 20,
            alignItems: "center"
        }}>
        <Typography level={"h1"} sx={{color: "#272D2D"}}>Aanmeldingen</Typography>

        <CourseCard course={course} large={true} variant={"administrator"}/>
        <div className={"flex-container"}
             style={{
                 display: "flex",
                 flexDirection: "row",
                 flexWrap: "wrap",
                 gap: 20,
                 justifyContent: "center",
                 width: "100%"
             }}>
            {applicants.map(user => {
                return <ApplicantCard user={user} course={course}
                                      accept={(courseId, applicantId) => {

                                          inviteApplicant(courseId, applicantId).then(() => {
                                              getCourse(courseId, setCourse)
                                              setMessage({text: "Uitnodiging verstuurd", color: "success"})
                                          })
                                      }}
                                      remove={(courseId, applicantId) => {
                                          removeApplicant(courseId, applicantId).then(() => {
                                              getCourse(courseId, setCourse)
                                              setMessage({text: "Uitnodiging ingetrokken", color: "warning"})
                                          })
                                      }}/>
            })}
        </div>
    </div>
}
