import * as React from 'react';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, {tabClasses} from '@mui/joy/Tab';

export const TabsSegmentedControls = ({currentTab, setCurrentTab}) => {
    return (
        <Tabs aria-label="tabs" value={currentTab}
              sx={{bgcolor: 'transparent', width: "fit-content",}}
              onChange={(e, newValue) => setCurrentTab(newValue)}>
            <TabList
                disableUnderline
                sx={{
                    fontSize: {xs: 14},
                    p: 0.5,
                    gap: 0.5,
                    mt: 1.5,
                    paddingLeft: 1, paddingRight: 1,
                    borderRadius: 'xl',
                    color: "#FFF",
                    bgcolor: '#272D2D',
                    [`& .${tabClasses.root}[aria-selected="true"]`]: {
                        boxShadow: 'sm',
                        bgcolor: 'background.surface',
                        color:"#272D2D"

                    },
                }}
            >
                <Tab value={"profile"} sx={{color:"#FFF"}} disableIndicator>Profiel</Tab>
                <Tab value={"courses"} sx={{color:"#FFF"}} disableIndicator>Geschiedenis</Tab>
                <Tab value={"applications"} sx={{color:"#FFF"}} disableIndicator>Aanmeldingen</Tab>
            </TabList>
        </Tabs>
    );
}