import {CourseCard} from "./CourseCard";
import "./card.css"
import {Typography} from "@mui/joy";
import Button from "@mui/joy/Button";

export const CourseOverviewCard = ({courses}) => {

    return (<div className={"course-overview-container"}>
        {courses && courses.map(course => {
            return (<CourseCard course={course}/>)
        })}
        {courses.length === 0 &&
            <><Typography
                level={"body-md"}>{window.location?.href.includes("/profiel") ? "Je hebt je nog niet voor een les aangemeld" : "Er zijn momenteel geen openstaande lessen"}</Typography>
                {window.location?.href.includes("/profiel") ?
                    <Button onClick={() => window.location.href = "/lessen"}>Bekijk het aanbod</Button> :
                    <Button onClick={() => window.location.href = "/profiel/applications"}>Bekijk je
                        aanmeldingen</Button>}
            </>}

    </div>)
}
