import {Typography} from "@mui/joy";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import Button from "@mui/joy/Button";
import CardOverflow from "@mui/joy/CardOverflow";
import {acceptInvitation, declineInvitation} from "../../../actions/applicant-calls";
import {useContext} from "react";
import {MessageContext} from "../../../contexts/Contexts";

export const AcceptedCard = ({step, acceptInvite, declineInvite}) => {
    const {setMessage} = useContext(MessageContext)
    let cardTitle, cardSubtitle, buttonDisabled, buttonText, secondButton, buttonVariant
    let disabled = false;
    if (step === 0 || step > 2)
        disabled = true;
    buttonDisabled = true
    buttonText = "Accepteren"
    buttonVariant = "primary"
    switch (step) {
        case 2:
            cardTitle = "De school heeft je uitgenodigd!"
            cardSubtitle = "Accepteer de uitnodiging om de les te geven"
            buttonDisabled = false
            buttonText = "Accepteren"
            buttonVariant = "success"
            secondButton = <Button onClick={declineInvite} color={"danger"}>Afwijzen</Button>
            break;
        case 3:
            cardTitle = "Je hebt de uitnodiging geaccepteerd"
            break
        case 0:
            cardTitle = "Meld je eerst aan om uitgenodigd te worden"
            break
        case 1:
            cardTitle = "De school is je aanmelding aan het beoordelen"
            break
    }
    return (<Card className={"apply-card"} style={disabled ? {filter: "blur(1px)"} : {borderColor: "#0b6bcb"}}>
        <Typography level={"title-sm"}>Uitnodiging</Typography>
        <CardContent>
            <Typography>{cardTitle}</Typography>
            <Typography>{cardSubtitle}</Typography>
        </CardContent>
        <CardOverflow>
            <CardActions>
                {secondButton}
                <Button color={buttonVariant} onClick={acceptInvite}
                        disabled={buttonDisabled}>{buttonText}</Button>
            </CardActions>
        </CardOverflow>
    </Card>)
}
