import {DelphiAPIClient} from "./axios-instance";
import {keys} from "../config/keys.dev";

export const getCourseTypes = async (setCourseTypes) =>{
    const response = await DelphiAPIClient.get(keys.url + "/api/get_course_types/")
    setCourseTypes(response.data)
}

export const getQualifications = async (setQualifications) =>{
    const response = await DelphiAPIClient.get(keys.url + "/api/get_qualifications/")
    setQualifications(response.data)
}