import {Sheet, Typography} from "@mui/joy";
import {Link, useLocation} from "react-router-dom";
import Button from "@mui/joy/Button";

export const WebNavBar = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    return <>
        <div style={{display: "block", width: "100%", height: 70}}></div>
        <Sheet className={"mobile-nav-container web-nav"}>
            <Link to={"/home"}>
                <img style={{height: process.env?.REACT_APP_LOGO?.includes("cvog") ? 45 : 50}}
                     src={process.env.REACT_APP_LOGO}/>
            </Link>
            <Link to={"/home"}
                  className={"web-nav-item" + (currentPath.includes("/home") ? " nav-active" : "")}>
                <Typography level={"body-md"}>Overzicht</Typography>
            </Link>
            <Link className={"web-nav-item" + (currentPath.includes("/scholen") ? " nav-active" : "")}
                  to={"/scholen"}>
                <Typography level={"body-md"}>Scholen</Typography>
            </Link>
            <Link className={"web-nav-item" + (currentPath.includes("/lessen") ? " nav-active" : "")}
                  to={"/lessen"}>
                <Typography level={"body-md"}>Lessen</Typography>
            </Link>
            <Link to={"/profiel"}
                  className={"web-nav-item" + (currentPath.includes("/profiel") ? " nav-active" : "")}>
                <Typography level={"body-md"}>Mijn profiel</Typography>
            </Link>
            <Button onClick={() => {
                localStorage.removeItem("userToken")
                window.location.href = "/login"
            }} variant={"outlined"} color={"danger"}>Uitloggen</Button>
        </Sheet></>
}