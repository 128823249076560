import Box from "@mui/joy/Box";
import {Fragment, useEffect, useRef, useState} from "react";
import {getDashboardStatistics} from "../../actions/statistic-calls";
import {getLatestApplications} from "../../actions/applicant-calls";
import CardContent from "@mui/joy/CardContent";
import {Typography} from "@mui/joy";
import Card from "@mui/joy/Card";
import "./dashboard.css"
import 'chart.js/auto';
import {Chart} from 'react-chartjs-2';
import CountUp from "react-countup";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Checkbox from "@mui/joy/Checkbox";
import Chip from "@mui/joy/Chip";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import {HourglassBottomRounded} from "@mui/icons-material";
import BlockIcon from "@mui/icons-material/Block";
import Avatar from "@mui/joy/Avatar";
import Link from "@mui/joy/Link";
import {Link as ReactLink} from "react-router-dom";
import * as React from "react";


const parseDate = (date) => {
    return new Date(date).toLocaleDateString("nl-NL") + " " + new Date(date).toLocaleTimeString("nl-NL").substring(0, 5)
}

export const Dashboard = () => {
    const [statistics, setStatistics] = useState({})
    const [applications, setApplications] = useState([])
    const statisticsRef = useRef(null);
    useEffect(() => {
        getDashboardStatistics(setStatistics)
        getLatestApplications(setApplications)
    }, []);

    useEffect(() => {
        statisticsRef.current = statistics
    }, [statistics])
    const openCoursesData = {
        labels: ['Open lessen', 'Gevulde lessen'],
        datasets: [
            {
                label: 'Totaal gevulde open lessen',
                data: [statistics.empty_open_courses, statistics.filled_open_courses],
                backgroundColor: [
                    "#ea5545",
                    "#87bc45",
                ],
                borderWidth: 1,
            }
        ]
    }
    const closedCoursesData = {
        labels: ['Lessen', 'Gevulde lessen'],
        datasets: [
            {
                label: 'Totaal gevulde lessen',
                data: [statistics.closed_empty_courses, statistics.closed_filled_courses],
                backgroundColor: [
                    "#ea5545",
                    "#87bc45",
                ],
                borderWidth: 1,
            }
        ]
    }

    return <Box sx={{
        width: 'calc(100vw - 260px)',
        left: 240, padding: "20px", position: "relative",
        minWidth: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: 8
    }}>
        <div className={"dashboard-container"}>
            <div className={"dashboard-row"}>
                <Card sx={{width: "30%", maxWidth: 300}}>
                    <CardContent>
                        <Typography textAlign={"center"}>
                            Totaal gevulde gegeven lessen
                        </Typography>
                        {!!statistics.empty_open_courses && <Chart type={"pie"} data={closedCoursesData}/>}
                    </CardContent>
                </Card>
                <Card sx={{width: 300}}>
                    <CardContent>
                        <Typography textAlign={"center"}>
                            Totaal gevulde open lessen
                        </Typography>
                        {!!statistics.empty_open_courses && <Chart type={"pie"} data={openCoursesData}/>}
                    </CardContent>
                </Card>
                <Card sx={{width: "30%", maxWidth: 300}}>
                    <CardContent>
                        <Typography textAlign={"center"}>
                            Totaal aantal gevulde uren
                        </Typography>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width: "100%"
                        }}>
                            {!!statistics.filled_minutes &&
                                <CountUp end={statistics.filled_minutes} duration={5} style={{fontSize: 50}}
                                         decimal={","} decimals={1} suffix={"u"}/>}
                        </div>
                    </CardContent>
                </Card>
            </div>
            <div className={"dashboard-row"}>
                <Card sx={{width: "calc(90% + 90px)", maxWidth: 1020}}>
                    Laatste aanmeldingen
                    <Fragment>
                        <Sheet
                            className="OrderTableContainer"
                            variant="outlined"
                            color={"neutral"}
                            sx={{
                                display: {xs: 'none', sm: 'block'},
                                width: '100%',
                                borderRadius: 'sm',
                                flexShrink: 1,
                                overflow: 'auto',
                                maxHeight: "30vh",
                                minHeight: "30vh",
                            }}
                        >
                            <Table
                                aria-labelledby="tableTitle"
                                stickyHeader
                                hoverRow
                                sx={{
                                    '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                                    '--Table-headerUnderlineThickness': '1px',
                                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                                    '--TableCell-paddingY': '4px',
                                    '--TableCell-paddingX': '8px',

                                }}
                            >
                                <thead>
                                <tr>
                                    <th style={{width: 100, padding: '12px 6px'}}>Les</th>
                                    <th style={{width: 60, padding: '12px 6px'}}>Status</th>
                                    <th style={{width: 100, padding: '12px 6px'}}>Datum</th>
                                    <th style={{width: 140, padding: '12px 6px'}}>School</th>
                                    <th style={{width: 180, padding: '12px 6px'}}>Aanmelder</th>
                                    <th style={{width: 100, padding: '12px 6px'}}>Aangemeld op</th>
                                    <th style={{width: 100, padding: '12px 6px'}}></th>
                                </tr>
                                </thead>
                                <tbody>
                                {applications.length === 0 && (
                                    <Typography style={{whiteSpace: "nowrap", marginLeft: 10, marginTop: 10}}>
                                        Geen recente aanmeldingen
                                    </Typography>)
                                }
                                {applications.map((row) => (
                                    <tr key={row.id}>
                                        <td>
                                            <Typography level="body-xs">{row.course_name}</Typography>
                                        </td>
                                        <td>
                                            <Chip
                                                variant="soft"
                                                size="sm"
                                                startDecorator={
                                                    {
                                                        open: <CheckRoundedIcon/>,
                                                        wachtrij: <HourglassBottomRounded/>,
                                                        dicht: <BlockIcon/>,
                                                    }[row.status]
                                                }
                                                color={
                                                    {
                                                        open: 'success',
                                                        wachtrij: 'warning',
                                                        dicht: 'danger',
                                                    }[row.status]
                                                }
                                            >
                                                {row.status}
                                            </Chip>
                                        </td>

                                        <td>
                                            <Typography level="body-xs">{parseDate(row.course_date)}</Typography>
                                        </td>
                                        <td>
                                            <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                                                <Avatar size="sm" src={row.school.school_picture}></Avatar>
                                                <div>
                                                    <Typography level="body-xs">{row.school.name}</Typography>
                                                    <Typography level="body-xs">{row.school.type}</Typography>
                                                </div>
                                            </Box>
                                        </td>
                                        <td>
                                            <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                                                <Avatar size="sm" src={row.profile_picture}></Avatar>
                                                <div>
                                                    <Typography
                                                        level="body-xs">{row.firstname + " " + row.lastname}</Typography>
                                                    <Typography level="body-xs">{row.email}</Typography>
                                                </div>
                                            </Box>
                                        </td>
                                        <td>
                                            <Typography level="body-xs">{parseDate(row.application_date)}</Typography>
                                        </td>
                                        <td>
                                            <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                                                <Link level="body-xs" component={ReactLink}
                                                      to={"/dashboard/courses/applicants/" + row.course_id}>
                                                    Bekijken
                                                </Link>
                                            </Box>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Sheet>

                    </Fragment>

                </Card>
            </div>
        </div>
    </Box>
}
