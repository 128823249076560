import {DelphiAPIClient} from "./axios-instance";
import {keys} from "../config/keys.dev";

export const getProfile = async (setProfile) => {
    const response = await DelphiAPIClient.get(keys.url + "/api/get_profile/")
    setProfile(response.data)
}

export const saveProfile = async (profile) => {
    await DelphiAPIClient.post(keys.url + "/api/save_profile/", profile);
}

export const getUser = async (setUser) => {
    const response = await DelphiAPIClient.get(keys.url + "/api/get_user/")
    setUser(response.data)
}

export const getUserById = async (userId, setUser) => {
    const response = await DelphiAPIClient.get(keys.url + `/api/get_user_by_id/${userId}/`)
    setUser(response.data)
}

export const getUsers = async (setUsers) => {
    const response = await DelphiAPIClient.get(keys.url + "/api/get_users/")
    setUsers(response.data)
}

export const inviteUser = async (firstname, lastname, email, school, role) => {
    await DelphiAPIClient.post(keys.url + "/api/invite_user/", {
        firstname,
        lastname,
        email,
        school,
        role
    });
}

export const updateProfilePicture = async (url) => {
    await DelphiAPIClient.post(keys.url + "/api/update_profile_picture/", {url});
}
