import {Fullscreen, Pause, PlayArrow} from "@mui/icons-material";
import React, {useEffect, useRef, useState} from "react";

export const VideoPlayer = ({url}) => {
    const [isPlaying, setIsPlaying] = useState(false)
    const [visible, setVisible] = useState(true)
    const [timerRunning, setTimerRunning] = useState(false)
    const videoRef = useRef(null)

    const playPauseVideo = (e) => {
        e.stopPropagation()
        if (videoRef.current.paused) {
            videoRef.current.play()
            setIsPlaying(true)
            setTimeout(() => setVisible(false), 2000)

        } else {
            videoRef.current.pause()
            setIsPlaying(false)
            setVisible(true)
        }
    }

    useEffect(() => {
        let timer;
        if (timerRunning) {
            timer = setTimeout(() => {
                setVisible(false);
                setTimerRunning(false);
            }, 2000);
        }
        return () => clearTimeout(timer);

    }, [timerRunning]);

    const toggleFullScreen = () => {
        const el = videoRef.current;
        if (el.requestFullscreen) {
            el.requestFullscreen();
        } else if (el.msRequestFullscreen) {
            el.msRequestFullscreen();
        } else if (el.mozRequestFullScreen) {
            el.mozRequestFullScreen();
        } else if (el.webkitRequestFullscreen) {
            el.webkitRequestFullscreen();
        }
    };


    return (<div style={{position: "relative", display: "flex", flexDirection: "column", alignItems: "center"}}>
        <video ref={videoRef} style={{borderRadius: 5}} className={"website-header-video"}
               onClick={playPauseVideo} muted={true} onMouseMove={() => {
            setVisible(true)
            setTimerRunning(true)
        }}>
            <source src={url}/>

        </video>
        {!isPlaying && <PlayArrow className={"play-button"}
                                  onClick={playPauseVideo}/>}
        {isPlaying &&
            <Pause style={{opacity: visible ? 1 : 0, transition: 'opacity 0.5s'}} className={"play-button"}

                   onClick={playPauseVideo}/>}
        <Fullscreen className={"full-screen"}
                    onClick={toggleFullScreen}/>
    </div>)
}