import "./profile.css"
import {useContext, useEffect, useState} from "react";
import {getProfile} from "../../actions/profile-calls";
import {ProfileCard} from "../cards/ProfileCard";
import {TabsSegmentedControls} from "../nav/TabsSegmentedControls";
import {CourseOverviewCard} from "../cards/CourseOverviewCard";
import {getAppliedCourses, getCompletedCourses} from "../../actions/course-calls";
import {Button} from "@mui/joy";
import {useParams} from "react-router-dom";
import {WidthContext} from "../../contexts/Contexts";

export const Profile = () => {
    const {initialTab} = useParams()
    const [profile, setProfile] = useState({})
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [title, setTitle] = useState("")
    const [titleExplanation, setTitleExplanation] = useState("")
    const [pitch, setPitch] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [tab, setTab] = useState(initialTab || "profile")
    const [qualifications, setQualifications] = useState([])
    const [dayPreferences, setDayPreferences] = useState([])
    const [timePreferences, setTimePreferences] = useState([])
    const [coursePreferences, setCoursePreferences] = useState([])
    const [completedCourses, setCompletedCourses] = useState([])
    const [appliedCourses, setAppliedCourses] = useState([])
    const {width} = useContext(WidthContext)
    useEffect(() => {
        getAppliedCourses(setAppliedCourses)
        getCompletedCourses(setCompletedCourses)
        getProfile((profile) => {
            setFirstname(profile.firstname)
            setLastname(profile.lastname)
            setTitle(profile.title)
            setTitleExplanation(profile.title_explanation)
            setPitch(profile.pitch)
            setQualifications(profile.qualifications)
            setEmail(profile.email)
            setPhone(profile.phone)
            setDayPreferences(profile.day_preferences)
            setTimePreferences(profile.time_preferences)
            setCoursePreferences(profile.course_preferences)
            setProfile(profile)
        })
    }, []);

    return <div className={"profile-container"}>
        <TabsSegmentedControls currentTab={tab} setCurrentTab={setTab}/>
        <br/>
        {width < 768 && <Button variant={"solid"} color={"danger"} onClick={() => {
            localStorage.removeItem("userToken")
            window.location.href = "/login"
        }}>Uitloggen</Button>}
        {tab === "profile" &&
            <ProfileCard firstname={firstname} lastname={lastname} title={title} titleExplanation={titleExplanation}
                         pitch={pitch} email={email} phone={phone} setFirstname={setFirstname} setLastname={setLastname}
                         setTitle={setTitle} setTitleExplanation={setTitleExplanation} setPitch={setPitch}
                         qualifications={qualifications} setQualifications={setQualifications}
                         setEmail={setEmail} setPhone={setPhone} setProfile={setProfile} profile={profile}
                         setDayPreferences={setDayPreferences} dayPreferences={dayPreferences}
                         setCoursePreferences={setCoursePreferences} coursePreferences={coursePreferences}
                         setTimePreferences={setTimePreferences} timePreferences={timePreferences}/>}
        {tab === "courses" && <CourseOverviewCard courses={completedCourses}/>}
        {tab === "applications" && <CourseOverviewCard courses={appliedCourses}/>}
    </div>
}
