import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ProfileCard} from "../../cards/ProfileCard";
import {getUserById} from "../../../actions/profile-calls";
import {InviteUserCard} from "../../cards/InviteUserCard";
import {saveCourse} from "../../../actions/course-calls";

export const CreateUser = () => {
    const {userId} = useParams("userId")
    const [profile, setProfile] = useState({})
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [title, setTitle] = useState("")
    const [titleExplanation, setTitleExplanation] = useState("")
    const [pitch, setPitch] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [qualifications, setQualifications] = useState([])
    const [dayPreferences, setDayPreferences] = useState([])
    const [timePreferences, setTimePreferences] = useState([])
    const [coursePreferences, setCoursePreferences] = useState([])


    useEffect(() => {
        if (userId)
            getUserById(userId, (profile) => {
                setFirstname(profile.firstname)
                setLastname(profile.lastname)
                setTitle(profile.title)
                setTitleExplanation(profile.title_explanation)
                setPitch(profile.pitch)
                setQualifications(profile.qualifications)
                setEmail(profile.email)
                setPhone(profile.phone)
                setDayPreferences(profile.day_preferences)
                setTimePreferences(profile.time_preferences)
                setCoursePreferences(profile.course_preferences)
                setProfile(profile)
            })
    }, [userId]);

    if (!userId) {
        return <InviteUserCard/>
    }
    return <ProfileCard firstname={firstname} lastname={lastname} title={title} titleExplanation={titleExplanation}
                        pitch={pitch} email={email} phone={phone} setFirstname={setFirstname} setLastname={setLastname}
                        setTitle={setTitle} setTitleExplanation={setTitleExplanation} setPitch={setPitch}
                        qualifications={qualifications} setQualifications={setQualifications}
                        setEmail={setEmail} setPhone={setPhone} setProfile={setProfile} profile={profile}
                        setDayPreferences={setDayPreferences} dayPreferences={dayPreferences}
                        setCoursePreferences={setCoursePreferences} coursePreferences={coursePreferences}
                        setTimePreferences={setTimePreferences} timePreferences={timePreferences}/>
}

