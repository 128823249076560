import AspectRatio from "@mui/joy/AspectRatio";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";

export const USPCard = ({title, subTitle, icon}) => {
    return (<Card
        variant="outlined"
        orientation="horizontal"
        sx={{
            width: {xs: 300, sm: 600},
            alignItems: "center",
            '&:hover': {boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder'},

        }}
    >
        {icon}
        <CardContent>
            <Typography level="title-lg" id="card-description">
                {title}
            </Typography>
            <Typography level="body-sm" aria-describedby="card-description" mb={1}>
                {subTitle}
            </Typography>
        </CardContent>
    </Card>)
}