import {DelphiAPIClient} from "./axios-instance";
import {keys} from "../config/keys.dev";

export const getGoals = async (setGoals) => {
    const response = await DelphiAPIClient.get(keys.url + "/api/get_goals/")
    setGoals(response.data)

}

export const postGoal = async (id, type, amount, timeWindow, callback) => {
    const response = await DelphiAPIClient.post(keys.url + "/api/post_goal/", {
        id,
        type,
        amount,
        time_window: timeWindow
    })
    callback()
}