import MuiAlert from "@mui/material/Alert";

import React from "react";
import {Snackbar} from "@mui/joy";

export const ErrorToast = ({error, setError, style}) => {
    return (<Snackbar
        anchorOrigin={{vertical: "top", horizontal: "center"}}
        open={!!error}
        onClose={() => setError("")}
        key={"top-center"}
        autoHideDuration={3000}
        color={'danger'}
        variant={"soft"}
    >
        {error}
    </Snackbar>)
}