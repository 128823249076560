import axios from "axios";
import Cookies from "js-cookie";

export const DelphiAPIClient = axios.create({
    headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Authorization: "Token " + localStorage.getItem("userToken"),
    },
});


