import {DelphiAPIClient} from "./axios-instance";
import {keys} from "../config/keys.dev";

export const getFilledCourses = async (setFilledCourses) => {
    const response = await DelphiAPIClient.get(keys.url + `/api/get_filled_courses/`)
    setFilledCourses(response.data?.filled_courses)
}

export const getDashboardStatistics = async (setStatistics) => {
    const response = await DelphiAPIClient.get(keys.url + `/api/get_dashboard_statistics/`)
    setStatistics(response.data)
}