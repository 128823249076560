export const timeUntil = (date) => {
    // Parse the input date if it's a string
    const targetDate = new Date(date);
    const now = new Date();
    const timeDiff = targetDate - now;

    // Check if the target date is in the past
    if (timeDiff < 0) {
        return ""; // Return empty string if the date has passed
    }

    // Convert time difference from milliseconds
    const minutesDiff = Math.floor(timeDiff / (1000 * 60));
    const hoursDiff = Math.floor(minutesDiff / 60);
    const daysDiff = Math.floor(hoursDiff / 24);

    // Determine the appropriate return based on the difference
    if (daysDiff > 0) {
        return `over ${daysDiff} dag(en)`;
    } else if (hoursDiff > 0) {
        return `over ${hoursDiff} uur`;
    } else if (minutesDiff > 0) {
        return `over ${minutesDiff} minuten`;
    } else {
        // If the time difference is less than a minute
        return "binnenkort";
    }
}
export const determineQualificationFromKey = (key) => {
    switch (key) {
        case "eerste-graads":
            return "Eerste graads"
        case "tweede-graads":
            return "Tweede graads"
        case "basisonderwijs":
            return "Basisonderwijs"
        default:
            return ""
    }

}
